<template>
  <div class="add-transformer">
    <CForm
      ref="addTransformer"
      v-on:submit.prevent="createTransformer"
      novalidate
      class="create-transformer-form"
    >
      <!--   HEADER   -->
      <CreateHeader
        :loading="loading"
        :descriptions="descriptions"
        :title="texts.createPage.title"
      />
      <!--   CONTENT   -->
      <div class="form-content content">
        <!--     NAME   -->
        <ClFormRow
          :label="texts.createPage.nameLabel"
          :toolTipText="descriptions.name"
        >
          <template #content>
            <CInput
              :placeholder="texts.createPage.namePlaceholder"
              v-model="formData.name"
              add-input-classes="col-sm-12"
              required
              :invalidFeedback="texts.invalidFeedback"
              :is-valid="nameValidate"
            />
          </template>
        </ClFormRow>
        <!--   Source Language     -->
        <ClFormRow
          :label="texts.createPage.sourceLanguageLabel"
          :toolTipText="descriptions.sourceLanguage"
        >
          <template #content>
            <multiselect
              v-model="formData.sourceLanguage"
              :options="languageOptions"
              :class="selectErrorClass"
              class="custom-multi-select zq--multiselect  zq--multiselect__select zq--multiselect__green"
              :searchable="false"
              :multiple="false"
              :preserve-search="true"
              :taggable="false"
              :selectLabel="selectLabel"
              :deselectLabel="selectLabel"
              required
              :invalidFeedback="texts.invalidFeedback"
              :is-valid="sourceLangValidate"
              :allow-empty="false"
              :block-keys="['Tab', 'Enter']"
            />
          </template>
        </ClFormRow>
        <!--     CODE   -->
        <ClFormRow
          :label="texts.createPage.codeLabel"
          :toolTipText="descriptions.code"
          md-content="12"
          xl-content="10"
        >
<!--          tooltip-position=""-->
          <template #content>
            <codemirror :options="editorOptions" v-model="formData.source" />
          </template>
        </ClFormRow>
      </div>
    </CForm>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import 'codemirror/mode/javascript/javascript.js'
import {codemirror} from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import CreateHeader from "@/shared/components/CreateHeader";
import ClFormRow from "@/shared/components/formComponents/ClFormRow";
import {formConfig} from "@/config";
import {transformers} from "@/config/descriptions/transformers"
import {transformersTexts} from "@/config/pageTexts/transformers.json";

export default {
  name: 'CreateTransformer',
  components: {
    ClFormRow,
    CreateHeader,
    codemirror
  },
  data() {
    return {
      descriptions: {
        ...transformers.create
      },
      texts: {
        ...transformersTexts
      },
      selectLabel: formConfig.selectLabel,
      formData: {
        name: '',
        SourceLanguage: [],
        source: transformersTexts.createPage.source,
      },
      languageOptions: ['Scala'],
      nameValidate: null,
      sourceLangValidate: null,
    };
  },
  computed: {
    ...mapGetters('transformers', ['success', 'message', 'loading']),
    ...mapGetters('theme', ['theme']),
    selectErrorClass() {
      const className = this.theme === 'main' ? 'error-validation--multiselect' : 'error-validation';

      return {
        [className]: (this.sourceLangValidate === false)
      }
    },
    editorOptions: function () {
      return {
        tabSize: 1,
        lineNumbers: true,
        lineWrapping: true,
        viewportMargin: Infinity,
        extraKeys: {'Ctrl-Space': 'autocomplete'},
      }
    }
  },
  created() {
    routerBreadcrumbs(this.$router.currentRoute);
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        if (val.name) this.nameValidate = true;
      },
    },
  },
  methods: {
    ...mapActions('transformers', ['handleCreateTransformers']),
    createTransformer() {
      this.nameValidate = !!this.formData.name;
      this.sourceLangValidate = !!this.formData.sourceLanguage;
      const body = [
        {
          sourceLanguage: this.formData.sourceLanguage,
          name: this.formData.name,
          source: this.formData.source,
        },
      ];

      if (this.nameValidate && this.sourceLangValidate) {
        this.handleCreateTransformers({createTransformerRequestArray: body})
          .then(data => {
            if (data.length) {
              this.$router.push({
                name: 'PreviewTransformer',
                params: {
                  id: data[0].id,
                  name: data[0].externalReference,
                }
              })
            } else {
              console.log('Something went wrong');
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
  .c-nxt-app__main-theme .vue-codemirror .CodeMirror.CodeMirror-wrap {
    resize: vertical;
    min-height: 291px;
  }
.add-transformer {
  height: 100%;
  .create-transformer-form {
    height: calc(100% - 50px);
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
      border-top: 1px solid var(--zq-content-border-color);
      .custom-multi-select.zq--multiselect.zq--multiselect__select {
        .multiselect__select {
          opacity: 1;
        }
      }
    }
  }
}
</style>